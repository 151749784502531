<script>
import def from "./default.vue";
export default {
  extends: def,
  data() {
    return {
      links: [
        { privateStyling: this.$t("default.general") },
        { privateTime: this.$t("time.title") },
        { privateSettings: this.$t("settings.title") },
        { privatePreview: this.$t("default.preview.title") }
      ]
    };
  },
  methods: {
    getData() {
      return this.$store.state.privateEvent.assistent;
    },
    checkStatus(index, data) {
      if (!data.title && index > 0) {
        return false;
      }

      if (!data.events.length && index > 1) {
        return false;
      }

      return true;
    }
  }
};
</script>
